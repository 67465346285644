export default {
    "title": "🇳🇱 Nederlands",

    "很棒的住宿体验": "Geweldig verblijf",
    "清爽干净，维护良好": "Fris en schoon, goed onderhouden",
    "很棒的酒店和员工": "Geweldig hotel & personeel",
    "价值符合预期": "Waarde zoals verwacht",
    "卓越的": "Uitstekend",
    "美丽的土地，周末度假的好去处。 有趣的地方": "Prachtig land, geweldige plek voor een weekendje weg. interessante plaats",

    "首页": "Home",
    "预订": "RESERVEREN",
    "关于": "Over",
    "发现": "Ontdekken",
    "会员": "Lidmaatschap",
    "推荐计划": "Verwijzingsprogramma",
    "个人": "Profiel",
    "我的": "Mijn",
    "推荐": "Verwijzing",
    "事件": "Evenement",
    "登出": "Uitloggen",
    "登录": "Inloggen",
    "注册": "Registreren",
    "语言": "Taal",
    "忘记密码": "Vergeten",
    "取消": "Annuleren",
    "确认": "Bevestigen",
    "酒店": "Hotel",
    "银行卡列表": "Bankkaartlijst",
    "银行列表": "Banklijst",
    "持卡人姓名": "Naam kaarthouder",
    "银行卡号": "Bankrekeningnummer",
    "支付密码": "Betalingswachtwoord",
    "提交": "Verzenden",
    "Pix列表": "Pix-lijst",
    "Pix类型": "Pix-type",
    "Pix账号": "Pix-rekening",
    "账号列表": "Rekeninglijst",
    "网络": "Netwerk",
    "地址": "Adres",
    "备注": "Opmerking",
    "充值金额": "Oplaadbedrag",
    "支付类型": "Betalingswijze",
    "支付银行": "Betalingsbank",
    "USDT地址": "USDT-adres",
    "二维码": "QR-code",
    "充值渠道": "Oplaadkanaal",
    "充值凭证": "Oplaadbewijs",
    "立即登录": "Nu inloggen",
    "用户名": "Gebruikersnaam",
    "密码": "Wachtwoord",
    "邀请码": "Uitnodigingscode",
    "确认密码": "Bevestig wachtwoord",
    "手机": "Mobiel",
    "金额": "Bedrag",
    "新增": "Nieuw",
    "钱包余额": "Accountbalans",
    "单价": "Prijs",
    "数量": "Aantal",
    "总额": "Totaal",
    "奖励": "Beloningen",
    "手机号码": "Telefoonnummer",
    "修改密码": "Wachtwoord wijzigen",
    "登录密码": "Inlogwachtwoord",
    "当前密码": "Huidig wachtwoord",
    "新密码": "Nieuw wachtwoord",
    "确认新密码": "Bevestig nieuw wachtwoord",
    "保存修改": "Wijzigingen opslaan",
    "充值": "Storten",
    "复制": "Kopiëren",
    "分享链接": "Deel link",
    "升级": "Upgraden",
    "登录时间": "Inlogtijd",
    "信誉分": "Betrouwbaarheid",
    "提现": "Opnemen",
    "提现账号": "Opname rekening",
    "你确定要删除此账号吗": "Weet je zeker dat je dit account wilt verwijderen",
    "你确定要删除此地址吗": "Weet je zeker dat je dit adres wilt verwijderen",
    "提示": "Tips",
    "是": "Ja",
    "否": "Nee",
    "全部": "Alles",
    "待处理": "In behandeling",
    "冻结中": "Bevroren",
    "已完成": "Voltooid",
    "我们的伙伴": "Onze partners",
    "关于我们": "OVER ONS",
    "创建时间": "Aanmaaktijd",
    "你确定要登出当前账号吗？": "Weet je zeker dat je wilt uitloggen uit het huidige account?",
    "正在寻找酒店": "Op zoek naar een hotel",
    "旅程价格": "Reisprijs",
    "佣金": "Commissies",
    "搜索": "Zoeken",
    "评分": "Beoordeling",
    "评论": "Opmerking",
    "评论-t2": "Recensie",
    "你还没有设置支付密码，请前往设置": "Je hebt nog geen betalingswachtwoord ingesteld, ga naar instellingen",
    "请输入": "Typ hier",
    "请选择": "Selecteer hier",
    "账户明细": "Accountdetails",
    "等级": "Niveau",
    "每日订单": "Dagelijkse bestellingen",
    "设置": "Instellingen",
    "语言设置": "Taalinstellingen",
    "客服": "Klantenservice",
    "客服2": "Service",
    "投诉": "Klachten",
    "投诉客户服务": "Klacht over klantenservice",
    "帮助": "Helpen",
    "复制成功": "Succesvol gekopieerd",
    "位置、中心": "Locatie, centrum",
    "你好": "Hallo",
    "我的账户": "Mijn account",
    "头像": "Avatar",
    "昵称": "Bijnaam",
    "设置昵称": "Bijnaam instellen",
    "输入酒店": "Voer hotel in",
    "住宿问答": "Vraag en antwoord over accommodatie",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "Als u meer informatie wilt over de accommodatie, bekijk dan vragen van andere gasten",
    "酒店问答_Q1": "Wat is het verschil tussen een tweepersoonskamer en een twin-kamer?",
    "酒店问答_A1": "Een tweepersoonskamer heeft een tweepersoonsbed en een twin-kamer heeft 2 eenpersoonsbedden. Als een kamer Double/Twin wordt genoemd, kan deze voor beide typen worden ingesteld. Was deze informatie nuttig?",
    "酒店问答_Q2": "Wat is inbegrepen in de prijs?",
    "酒店问答_A2": "U kunt controleren of andere kosten zoals ontbijt, belastingen of servicekosten zijn inbegrepen. Deze informatie is ook te vinden in uw bevestigingsmail na het boeken en u kunt uw reservering ook bekijken in uw account.",
    "立即预订可享受酒店特别折扣": "Boek nu met speciale hotelaanbiedingen",
    "最低": "MIN",
    "折扣": "KORTING",
    "订单时间": "Besteltijd",
    "产品": "Product",
    "订单数量": "Aantal bestellingen",
    "提交订单": "Bestelling plaatsen",
    "可用余额": "Beschikbaar saldo",
    "冻结金额": "Bevroren bedrag",
    "佣金比例": "Commissiepercentage",
    "获得佣金": "Commissie verdienen",
    "完成订单": "Bestelling voltooid",
    "请至少选择一项": "Selecteer minimaal één",
    "请先选择星星": "Selecteer eerst de ster",
    "公告": "Aankondiging",
    "邀请好友": "Vrienden uitnodigen",
    "邀请好友赚取佣金": "Nodig vrienden uit en verdien commissie",
    "条款&协议": "Voorwaarden en overeenkomsten",
    "注册协议": "Registratieovereenkomst",
    "我已阅读并同意": "Ik heb gelezen en ga akkoord",
    "加载中...": "Laden...",
    "没有更多数据": "Geen verdere gegevens",
    "信息": "Bericht",
    "快乐的客户": "Tevreden klant",
    "快乐的酒店老板": "Gelukkige hoteleigenaar",
    "评论1": "Geweldig platform met geweldige functies, echt de moeite waard om te verkennen!",
    "评论2": "Proactieve klantenservice die u begeleidt als u onduidelijkheden over het platform heeft.",
    "评论3": "Vertrouwd platform, het proberen waard!",
    "评论4": "Het lukt mij om mijn hotel hier te vermelden en een geweldig marketingresultaat te behalen.",
    "客户评价": "klanten-reviews",
    "查看我们用户的真实评价。": "Bekijk echte beoordelingen van onze gebruikers.",
    "工作时间": "Werk tijd",
}