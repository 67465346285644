export default {
    "title": "🇻🇳 Tiếng Việt",

    "很棒的住宿体验": "Trải nghiệm lưu trú tuyệt vời",
    "清爽干净，维护良好": "Sạch sẽ, bảo trì tốt",
    "很棒的酒店和员工": "Khách sạn và nhân viên tuyệt vời",
    "价值符合预期": "Giá trị như mong đợi",
    "卓越的": "Xuất sắc",
    "美丽的土地，周末度假的好去处。 有趣的地方": "Đất đẹp, thích hợp nghỉ dưỡng cuối tuần. nơi thú vị",

    "首页": "Trang chủ",
    "预订": "ĐẶT PHÒNG",
    "关于": "Về chúng tôi",
    "发现": "Khám phá",
    "会员": "Thành viên",
    "推荐计划": "Chương trình giới thiệu",
    "个人": "Hồ sơ",
    "我的": "Của tôi",
    "推荐": "Giới thiệu",
    "事件": "Sự kiện",
    "登出": "Đăng xuất",
    "登录": "Đăng nhập",
    "注册": "Đăng ký",
    "语言": "Ngôn ngữ",
    "忘记密码": "Quên mật khẩu",
    "取消": "Hủy",
    "确认": "Xác nhận",
    "酒店": "Khách sạn",
    "银行卡列表": "Danh sách thẻ ngân hàng",
    "银行列表": "Danh sách ngân hàng",
    "持卡人姓名": "Tên chủ thẻ",
    "银行卡号": "Số tài khoản ngân hàng",
    "支付密码": "Mật khẩu thanh toán",
    "提交": "Gửi",
    "Pix列表": "Danh sách Pix",
    "Pix类型": "Loại Pix",
    "Pix账号": "Tài khoản Pix",
    "账号列表": "Danh sách tài khoản",
    "网络": "Mạng",
    "地址": "Địa chỉ",
    "备注": "Ghi chú",
    "充值金额": "Số tiền nạp",
    "支付类型": "Loại thanh toán",
    "支付银行": "Ngân hàng thanh toán",
    "USDT地址": "Địa chỉ USDT",
    "二维码": "Mã QR",
    "充值渠道": "Kênh nạp tiền",
    "充值凭证": "Chứng từ nạp tiền",
    "立即登录": "Đăng nhập ngay",
    "用户名": "Tên đăng nhập",
    "密码": "Mật khẩu",
    "邀请码": "Mã giới thiệu",
    "确认密码": "Xác nhận mật khẩu",
    "手机": "Điện thoại",
    "金额": "Số tiền",
    "新增": "Thêm mới",
    "钱包余额": "Số dư tài khoản",
    "单价": "Giá",
    "数量": "Số lượng",
    "总额": "Tổng số tiền",
    "奖励": "Phần thưởng",
    "手机号码": "Số điện thoại",
    "修改密码": "Thay đổi mật khẩu",
    "登录密码": "Mật khẩu đăng nhập",
    "当前密码": "Mật khẩu hiện tại",
    "新密码": "Mật khẩu mới",
    "确认新密码": "Xác nhận mật khẩu mới",
    "保存修改": "Lưu thay đổi",
    "充值": "Nạp tiền",
    "复制": "Sao chép",
    "分享链接": "Chia sẻ liên kết",
    "升级": "Nâng cấp",
    "登录时间": "Thời gian đăng nhập",
    "信誉分": "Điểm uy tín",
    "提现": "Rút tiền",
    "提现账号": "Tài khoản rút tiền",
    "你确定要删除此账号吗": "Bạn có chắc muốn xóa tài khoản này không",
    "你确定要删除此地址吗": "Bạn có chắc muốn xóa địa chỉ này không",
    "提示": "Lời nhắc",
    "是": "Có",
    "否": "Không",
    "全部": "Tất cả",
    "待处理": "Đang chờ xử lý",
    "冻结中": "Đang bị đóng băng",
    "已完成": "Đã hoàn thành",
    "我们的伙伴": "Đối tác của chúng tôi",
    "关于我们": "VỀ CHÚNG TÔI",
    "创建时间": "Thời gian tạo",
    "你确定要登出当前账号吗？": "Bạn có chắc muốn đăng xuất khỏi tài khoản hiện tại không?",
    "正在寻找酒店": "Đang tìm kiếm khách sạn",
    "旅程价格": "Giá hành trình",
    "佣金": "Hoa hồng",
    "搜索": "Tìm kiếm",
    "评分": "Điểm đánh giá",
    "评论": "Bình luận",
    "评论-t2": "Đánh giá",
    "你还没有设置支付密码，请前往设置": "Bạn chưa đặt mật khẩu thanh toán, vui lòng đến cài đặt",
    "请输入": "Vui lòng nhập",
    "请选择": "Vui lòng chọn",
    "账户明细": "Chi tiết tài khoản",
    "等级": "Cấp độ",
    "每日订单": "Đơn hàng hàng ngày",
    "设置": "Cài đặt",
    "语言设置": "Cài đặt ngôn ngữ",
    "客服": "Dịch vụ khách hàng",
    "客服2": "Dịch vụ",
    "投诉": "Khiếu nại",
    "投诉客户服务": "Khiếu nại về dịch vụ khách hàng",
    "帮助": "Trợ giúp",
    "复制成功": "Sao chép thành công",
    "位置、中心": "Vị trí, trung tâm",
    "你好": "Xin chào",
    "我的账户": "Tài khoản của tôi",
    "头像": "Hình đại diện",
    "昵称": "Biệt danh",
    "设置昵称": "Đặt biệt danh",
    "输入酒店": "Nhập tên khách sạn",
    "住宿问答": "Câu hỏi và trả lời về chỗ ở",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "Nếu bạn muốn biết thêm thông tin về chỗ ở, vui lòng xem câu hỏi của khách hàng khác",
    "酒店问答_Q1": "Phòng đôi và phòng Twin khác nhau như thế nào?",
    "酒店问答_A1": "Phòng đôi có một giường đôi và phòng Twin có 2 giường đơn. Nếu một phòng được gọi là Double/Twin, nó có thể được thiết lập cho cả hai loại. Thông tin này có hữu ích không?",
    "酒店问答_Q2": "Giá bao gồm gì?",
    "酒店问答_A2": "Bạn có thể kiểm tra xem các khoản phí khác như bữa sáng, thuế hoặc phí dịch vụ có được bao gồm hay không. Thông tin này cũng có thể được tìm thấy trong email xác nhận đặt phòng của bạn, và bạn cũng có thể xem đặt phòng của mình trong tài khoản của bạn.",
    "立即预订可享受酒店特别折扣": "Đặt ngay để được giảm giá đặc biệt của khách sạn",
    "最低": "TỐI THIỂU",
    "折扣": "GIẢM GIÁ",
    "订单时间": "Thời gian đặt hàng",
    "产品": "Sản phẩm",
    "订单数量": "Số lượng đặt hàng",
    "提交订单": "Đặt hàng",
    "可用余额": "Số dư khả dụng",
    "冻结金额": "Số tiền đóng băng",
    "佣金比例": "Tỷ lệ hoa hồng",
    "获得佣金": "Nhận hoa hồng",
    "完成订单": "Hoàn thành đơn hàng",
    "请至少选择一项": "Vui lòng chọn ít nhất một",
    "请先选择星星": "Vui lòng chọn ngôi sao trước",
    "公告": "Thông báo",
    "邀请好友": "Mời bạn bè",
    "邀请好友赚取佣金": "Mời bạn bè và kiếm hoa hồng",
    "条款&协议": "Điều khoản & Thỏa thuận",
    "注册协议": "Thỏa thuận đăng ký",
    "我已阅读并同意": "Tôi đã đọc và đồng ý",
    "加载中...": "Đang tải...",
    "没有更多数据": "Không có dữ liệu nào",
    "信息": "Thông tin",
    "快乐的客户": "Chúc mừng khách hàng",
    "快乐的酒店老板": "Chủ khách sạn vui vẻ",
    "评论1": "Nền tảng tuyệt vời với tính năng tuyệt vời, thực sự đáng để khám phá!",
    "评论2": "Dịch vụ khách hàng chủ động sẽ hướng dẫn bạn nếu bạn chưa rõ về nền tảng này.",
    "评论3": "Nền tảng đáng tin cậy, đáng để thử!",
    "评论4": "Quản lý để liệt kê khách sạn của tôi ở đây và đạt được kết quả tiếp thị tuyệt vời.",
    "客户评价": "Phản hồi khách hàng",
    "查看我们用户的真实评价。": "Kiểm tra đánh giá thực tế từ người dùng của chúng tôi.",
    "工作时间": "Giờ làm việc",
}